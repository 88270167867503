import ExperienceAccordion from "../../interfaces/IExperienceAccordion";

const ExperienceAccordionData: ExperienceAccordion[] = [
  {
    id: "item1",
    buttonLabel: "Graduate Software Engineer @ Xero",
    workType: "Full-time",
    workPeriod: "Feb 2024 - Current",
    description:
      "My favorite aspect has been observing how software engineering decisions are made, as well as getting hands-on engineering experience to tackle industry/growth challenges and requirements.\n\nUnder the guidance of experienced engineers, I am getting a deeper understanding of CLEAN architecture principles, various levels of software testing.\n\nI have been working on new & legacy applications of both microservice/monolithic architectures, as well as automation of various tasks.\n\n",
    companyImagePath: "/experience/xero_logo.png",
    technologies: [
      "C#/.NET",
      "SQL",
      "DynamoDB",
      "StepFunctions",
      "Health Monitoring",
      "Unit/Component/Integration/E2E Testing",
      "Containerization",
    ],
  },
  {
    id: "item2",
    buttonLabel: "Software Engineer @ JDoodle",
    workType: "Full-time",
    workPeriod: "May 2023 - February 2024",
    description:
      "JDoodle is an Australian startup providing web-based compilers & coding-related solutions. I worked in the front-end team, wearing many hats at once. I was responsible for framework upgrade (Vue 3), implementing new features, and decision making on frontend architecture.\n\nMy most memorable achievement was designing the architecture & implementing a content-management application, which enabled the marketing team to freely add new articles/documentation/blogs to the website without the need for a software engineer to make changes to the code.\n\nManaging a large enterprise-level code base is difficult - so I adhered to best practices/patterns particularly the Single Responsibility Principle (SRP), Dependency Inversion Principle (DIP) and Component State Management.",
    companyImagePath: "/experience/jdoodle_logo.png",
    technologies: [
      "Typescript",
      "Vue 2",
      "Vue 3",
      "GraphQL",
      "Docker",
      "Strapi",
      "SRP/DIP",
      "State Management",
    ],
  },
  {
    id: "item3",
    buttonLabel: "Software Engineer Intern @ Xero",
    workType: "Full-time",
    workPeriod: "November 2022 - February 2023",
    description:
      "Worked in a back-end team that specialised in API development at an ASX-listed accounting software company. I was responsible for developing features, debugging, writing unit tests, and configuring Docker containers. Experienced elements of Extreme Programming and contributed in an Agile environment.\n\nMy greatest achievement was creating a new learning projects for future interns joining this team, since this team had not previously had an intern.",
    companyImagePath: "/experience/xero_logo.png",
    technologies: ["C#", "Dotnet", "Docker", "AWS", "TeamCity", "Git"],
  },
  {
    id: "item4",
    buttonLabel: "Frontend Developer @ Pegboard Software",
    workPeriod: "July 2022 - Ongoing",
    workType: "Part-time",
    description:
      "Developing custom web-applications and CRMs at a software company, for clients from various industries. My role requires me to be autonomous, proactively make decisions, take initiatives to learn new technologies/libraries, and communicate with various stakeholders through creating clear documentation.\n\nMy biggest achievement was leading the development of a new e-commerce app that incorporates 3D models.",
    companyImagePath: "/experience/pegboardco_logo.png",
    technologies: [
      "TypeScript",
      "Angular",
      "Vectary",
      "HTML",
      "CSS",
      "JavaScript",
    ],
  },
  {
    id: "item5",
    buttonLabel: "Web Desginer and Developer @ Bernies Music Land",
    workType: "Casual",
    workPeriod: "May 2022 - Feb 2023",
    description:
      "Responsible for the maintenance, debugging and development of multiple legacy websites for a piano distribution business. Work invovled using code and WordPress WooCommerce.\n\nMy greatest achievement was producing the initial design and hi-fidelity prototype of the new website, balancing between complex UX and business requirements.",
    companyImagePath: "/experience/bml_logo.jpg",
    technologies: [
      "HTML",
      "CSS",
      "Photoshop",
      "Figma",
      "WordPress",
      "PHP",
      "SQL",
    ],
  },
];

export default ExperienceAccordionData;
