import IHardAndSoftSkill from "../../interfaces/IHardAndSoftSkill";

const HardAndSoftSkillsData: IHardAndSoftSkill[] = [
  {
    proficiencyTitle: "Expert",
    technolgies: [
      "C#",
      "Dotnet",
      "JavaScript/TypeScript",
      "HTML/CSS",
      "Software Testing",
    ],
  },
  {
    proficiencyTitle: "Proficient",
    technolgies: [
      "AWS",
      "SQL",
      "OOP, SOLID Principles",
      "MicroServices, CLEAN architecture",
      "Docker",
      "Angular",
      "React",
      "Vue3",
    ],
  },
  {
    proficiencyTitle: "Intermediate",
    technolgies: ["Kotlin", "Python", "Networking", "ReactNative"],
  },
  {
    proficiencyTitle: "Soft Skill Highlights",
    technolgies: [
      "Clear Communicator",
      "Empathetic & Respectful",
      "Open-minded",
      "Feedback-seeking",
      "Quick Learner",
      "Accountable",
    ],
  },
];

export default HardAndSoftSkillsData;
