import IExpertiseData from "../../interfaces/IExpertiseData";

const ExpertiseData: IExpertiseData[] = [
  {
    expertiseTitle: "Backend Engineering",
    description:
      "Enterprise-level experience implementing complex web applications/services. Particularly interested in decisions that impact performance, database design, scalability and security.",
    descriptionHighlight: undefined,
    graphicPath: "/expertise/software-development.png",
  },
  {
    expertiseTitle: "Frontend Engineering",
    description:
      "2+ years of frontend experience using legacy & modern patterns/frameworks (Vue2, Vue3, Angular, React). Preference for Typescript for code maintainability.",
    descriptionHighlight:
      "Meticulous about Responsiveness - Try on Mobile/Resize this site!",
    graphicPath: "/expertise/frontend-development.png",
  },
  {
    expertiseTitle: "Web Design",
    description:
      "As a side hobby, I design and deliver simple websites for small businesses, driven by best UX principles.",
    descriptionHighlight: undefined,
    graphicPath: "/expertise/web-design.png",
  },
];

export default ExpertiseData;
